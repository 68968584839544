
@media (min-width: 1480px) {
 

}

@media (max-width: 760px) {

    .areaInfo{
        flex-wrap: wrap;
    }

    .areaInfo > * + * { margin-left: 0px; margin-bottom: 20px;}

    .paginaLogin{
        flex-direction: column;
        margin: 0;
    }
    .areaMarca{
        width: 100vw; 
        height: 25%;      
    }
    .areaMarca img{
        width: 100px;
    }
    .areaLogin{
        width: 100vw;       
        height: 75%;
        background-image: none;
        padding: 30px;
    }

    h1{
        font-size: 1.4em;
    }

    .camposH{
        flex-direction: column;
    }

    .camposH > * + * { margin-left: 0px;}
}