.over {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    align-items: center;
    align-content: center;
    justify-content: center;
    background-color: #6d819c8c;
    z-index: 9999;
}

.container {
    width: 100%;
    max-width: 960px;
    min-height: 260px;
    padding: 28px;
    border-radius: 7px;
    background-color: #fff;
    margin: 20px;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.1);
    position: relative;
}

.btnClose {
    position: absolute;
    right: 20px;
    top: 20px;
    font-size: 24px;
    color: #bbb;
    transition: all linear 165ms;
    cursor: pointer;
    padding: 5px;
}

.btnClose:hover {
    color: #6d819c;
}